import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  ViewChild
} from '@angular/core';
import {CustomerModule} from '../../../classes/customer-module';
import {ModulePlan} from '../../../classes/module-plan';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {ServiceValues} from '../../../classes/messaging/service-values';
import {ServiceModule} from '../../../classes/messaging/service-module';
import {ServiceMessage} from '../../../classes/messaging/service-message';
import {MessageTypes} from '../../../classes/enums/message-types.enum';
import {ContextTypes} from '../../../classes/enums/context-types.enum';
import {Entities} from '../../../classes/enums/entities.enum';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MessageBoxComponent} from '../../dialogs/message-box/message-box.component';
import {Router} from '@angular/router';
import {NetworkDevice} from '../../../classes/messaging/network-device';
import {ModuleComponent} from '../../../classes/module-component';
import {ComponentVariable} from '../../../classes/component-variable';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {DownloadFromModuleDialogComponent} from '../../dialogs/download-from-module-dialog/download-from-module-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as uuidV4 from 'uuid/v4';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {NotificationEntry} from '../../../classes/notificationEntry';
import {RunServiceConfigurationComponent} from '../../dialogs/run-service-configuration/run-service-configuration.component';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';
import {environment} from '../../../../environments/environment';
import compareVersions from 'compare-versions';

@Component({
  selector: 'app-module-settings',
  templateUrl: './module-settings-cat.component.html',
  styleUrls: ['./module-settings.component.css']
})

export class ModuleSettingsComponent implements OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, AfterViewChecked, OnDestroy {

  public customerModule: CustomerModule;
  private _inventoryView = false;
  public _modulePlan: ModulePlan;
  public originModule: CustomerModule;

  public left = '0px';
  public top = '10px';
  public backgroundImage = '';
  public configOn = true;
  public settingWidth = '40%';
  public lineWidth = '3px';
  public ImageLastWidth = 0;
  public ImageLastHeight = 0;
  public ScaleX = 1;
  public ScaleY = 1;
  private messageIdRecipe: string;
  private lastChanged: ComponentVariable;
  private panDeltaY = 0;
  public CommentOnEdit = false;
  public selectedComponent: string;
  private modulePathData: string;
  private completeModulePathData: string;
  public msgId: string;
  public currentLocation = 'localhost';

  private ShowMonitoring = false;
  private ShowParameter = false;
  private ShowService = false;

  private demoHandler: any;

  get inventoryView(): boolean {
    return this._inventoryView;
  }

  private ServiceSelectionChanged = false;

  @ViewChild('moduleImage', {static: true}) imageElement: ElementRef;
  @ViewChild('moduleComponents') moduleComponents: ElementRef;
  @ViewChild('deviceConfigElements', {read: ElementRef, static: false}) public widgetsContent: ElementRef<any>;

  @Input() set SelectedModule(value: ModulePlan) {
    if (this.moduleHandling.SupportSettingsToShow) {
      this._modulePlan = this.moduleHandling.getSupportModuleForModulePlan(value);

      // #510 set config to service




    } else {
      this._modulePlan = value;
    }

    if (this._modulePlan.customerModule) {
      this.customerModule = this._modulePlan.customerModule.Copy();
      this.customerModule.Module.UniqueId = this._modulePlan.modul.UniqueId;
      this.originModule = this._modulePlan.customerModule;
      this._modulePlan.modul.OnSwitchModeChanged.subscribe(this.SwitchModeChanged.bind(this));
      this.customerModule.Module.OnSwitchModeChanged.subscribe(this.SwitchModeChanged.bind(this));

    }

    this.updateServiceValuesFromConfig();



    this.backgroundImage = 'url("' + this._modulePlan.modul.PathDetailImage + '")';
    const names: any[] = [];
    for (const dev of this._modulePlan.modul.Components) {
      if (dev.Connections !== undefined && dev.Connections !== null && dev.Connections.length > 0) {
        const cp = this._modulePlan.connections.find(ex => dev.Connections.find(et => et === ex.moduleConnection));

        if (cp !== null && cp !== undefined) {
          if (cp.conveyor === null || cp.conveyor === undefined) {
            names.push(dev);
          }
        } else {
          names.push(dev);
        }
      }
    }

    // LOAD SVG PATHS
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    this.httpClient.get(this._modulePlan.modul.PathComponents, {headers, responseType: 'text'}).subscribe((data: any) => {
      // this.moduleComponents.nativeElement.innerHTML = data;
      // https://stackoverflow.com/questions/48817261/click-event-not-work-in-innerhtml-string-angular-4/48817568
      let element = data as string;
      for (const comp of this._modulePlan.modul.Components) {
        const id = comp.PlcKey;
        if (element.indexOf('id="' + id + '"') >= 0) {
          element = element.replace('id="' + id + '"', 'id="' + id + '"  onclick="Window.ModuleSettingsComponent.SelectComponent(\'' + id + '\')"');
        }
      }
      this.modulePathData = element;
      this.completeModulePathData = element;
      this.moduleComponents.nativeElement.innerHTML =
        this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(element));

      if (this.customerModule.Module.CurrentMode === ModeSwitch.SERVICE) {
        this.SelectServiceTab();
      } else {
        this.SelectParameterTab();
      }

    });

    if (this._modulePlan.customerModule) {
      this.customerModule.Module.Components = this.customerModule.Module.Components.filter(ez => !names.find(et => et === ez));
    }

    if (this.currentLocation === 'localhost' || this.currentLocation === 'theme') {
      this.setRandomNotifications();
    }

    if (environment.demo === true) {
      console.log('set notifications');
      this.messaging.onDemoNotification(value.customerModule);
      this.demoHandler = setInterval(et => {
        this.messaging.onDemoNotification(value.customerModule);
      }, 1000);


    }

  }
  @Input() set inventoryView(value: boolean) {
    this._inventoryView = value;
  }
  @Input() monitoringMsgId: string;

  @Output() ConfiguratedModule = new EventEmitter<CustomerModule>();

  constructor(public moduleHandling: ModuleHandlingService,
              public messaging: MessageHandlingService,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer,
              public httpClient: HttpClient,
              public router: Router,
              public translate: BluectrlTranslateService,
              private clusterOnboarding: ClusterOnboardingService,
              private cdRef: ChangeDetectorRef) {

    const location = window.location.href;

    if (location.indexOf('theme') >= 0) {
      this.currentLocation = 'theme';
    } else if (location.indexOf('localhost') >= 0) {
      this.currentLocation = 'localhost';
    } else {
      this.currentLocation = 'other';
    }

    Window['ModuleSettingsComponent'] = this;
    this.messaging.MonitoringValuesReceived.subscribe(this.GetMonitoringMsg.bind(this));
    this.messaging.ModuleModeChanged.subscribe(this.ModeSwitchChanged.bind(this));
    this.messaging.ModuleConfigResponse.subscribe(this.GetRecipeMsg.bind(this));
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if(this.demoHandler) {
      clearInterval(this.demoHandler);
    }
  }

  private SwitchModeChanged(currentState: ModeSwitch) {
    this.moduleHandling.ReopenModuleSettings();
  }

  private updateServiceValuesFromConfig() {
    for(const comp of this.customerModule.Module.Components) {
      for(const param of comp.Configs) {
        const serviceParam = comp.Maintenance.find(ex => ex.Name === param.Name);
        if (serviceParam) {
          serviceParam.SetValue(param.CurrentValue, false);
        }
      }
    }
  }

  public ScrollDown(distance: number = 50) {
    // this.widgetsContent.nativeElement.scrollTop =  this.widgetsContent.nativeElement.scrollTop + distance;
  }

  public ScrollUp(distance: number = -50) {
    /**
     this.widgetsContent.nativeElement.scrollTop =
     this.widgetsContent.nativeElement.scrollTop >= 0 ?
     this.widgetsContent.nativeElement.scrollTop + distance :
     0;
     **/
  }

  public MouseWheelRotation(event: any) {
    if (event.deltaY < 0) {
      this.ScrollUp(event.deltaY);
    } else if (event.deltaY > 0) {
      this.ScrollDown(event.deltaY);
    }
  }

  public movePanUp(event: any) {

  }

  public panStarts(event: any) {

    this.panDeltaY = 0;
  }

  public ModeSwitchChanged(netdevice: NetworkDevice) {
    if ((this.moduleHandling.CurrentViewMode === ViewCode.live ||
      this.moduleHandling.CurrentViewMode === ViewCode.inventory) &&
      this.customerModule && this.customerModule.SerialNumberSetted) {
      if (this.customerModule.SerialNumber === netdevice.serialnumber) {
        if (netdevice.switchstate === 'service') {
          this.customerModule.Module.EnableServiceMode();
          // this.customerModule.Module.CurrentMode = ModeSwitch.SERVICE;
        } else if (netdevice.switchstate === 'crane') {
          this.customerModule.Module.CurrentMode = ModeSwitch.CRANE;
        } else {
          this.customerModule.Module.DisableServiceMode();
          // this.customerModule.Module.CurrentMode = ModeSwitch.AUTOMATIC;
        }
      }
    }
    if (this.customerModule.Module.CurrentMode === ModeSwitch.AUTOMATIC) {
      this.SelectParameterTab();
    } if (this.customerModule.Module.CurrentMode === ModeSwitch.SERVICE) {
      this.SelectServiceTab();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

  }

  ngAfterContentInit(): void {

  }

  ngAfterContentChecked(): void {
    if (this.ImageLastHeight !== this.imageElement.nativeElement.height || this.ImageLastWidth !== this.imageElement.nativeElement.width) {
      this.ImageLastHeight = this.imageElement.nativeElement.height;
      this.ImageLastWidth = this.imageElement.nativeElement.width;

      this.ScaleX = this.ImageLastWidth / 800;
      this.ScaleY = this.ImageLastHeight / 500;
    }

  }

  private GetRecipeMsg(msg: any) {

    if (this.moduleHandling.CurrentViewMode === ViewCode.live || this.moduleHandling.CurrentViewMode === ViewCode.inventory) {
      if (this.messageIdRecipe !== null && this.messageIdRecipe !== undefined &&
        msg !== null && msg !== undefined) {
        if (this.customerModule.SerialNumber === msg.ctxId && this.messageIdRecipe === msg.msgId) {
          if (msg.recipe) {
            for (const dev of msg.recipe) {
              const devx = this.customerModule.Module.Components.find(ex => ex.PlcKey === dev.device);
              if (dev.parameter && devx) {
                for (const p of dev.parameter) {
                  const value = devx.Configs.find(ex => ex.Name === p.name);
                  if (value) {
                    if (!isNaN(p.value)) {
                      p.value = Math.round(p.value * 100) / 100;
                    }

                    if (value.ShowPositiveOnly === true) {
                      if (p.value < 0) {
                        value.SetValue(p.value * -1.0);
                      } else {
                        value.SetValue(p.value);
                      }
                    } else {
                      value.SetValue(p.value);
                    }


                  } else {
                    console.log('not found: ' + p.name + '/' + p.value);
                  }
                }
              } else {
                console.log('Device not found: ' + dev.device);
              }

            }
          }
        }
      }
    }

  }

  private GetMonitoringMsg(msg: any) {

    if (this.moduleHandling.CurrentViewMode === ViewCode.live || this.moduleHandling.CurrentViewMode === ViewCode.inventory) {
      if (this.monitoringMsgId !== null && this.monitoringMsgId !== undefined &&
        msg !== null && msg !== undefined) {
        if (this.customerModule.SerialNumber === msg.ctxId && this.monitoringMsgId === msg.msgId) {
          if (msg.devices !== null && msg.devices !== undefined) {
            for (const param of msg.devices) {
              const dev = this.customerModule.Module.Components.find(ex => ex.PlcKey.toUpperCase() === param.name.toUpperCase());
              if (dev !== undefined && dev !== null) {
                for (const p of param.parameter) {
                  // Extract array index if present
                  const match = p.param.match(/^(.+?)\[(\d+)\]$/);
                  if (match) {
                    // This is an array parameter
                    const baseName = match[1];
                    const arrayIndex = parseInt(match[2]);
                    const value = dev.Monitorings.find(ex => ex.Name.toUpperCase() === baseName.toUpperCase());
                    if (value !== null && value !== undefined) {
                      if (!isNaN(p.value)) {
                        p.value = Math.round(p.value * 100) / 100;
                      }
                      // Initialize array values object if needed
                      if (!value.CurrentValue || typeof value.CurrentValue !== 'object') {
                        value.CurrentValue = {};
                      }
                      // Store the value at the array index
                      value.CurrentValue[arrayIndex] = p.value;
                      console.log('arrayindex: ' + param.name + '/' + p.param + ' = ' + p.value);
                      console.log('hence, ' + value.CurrentValue[arrayIndex]);
                    } else {
                      console.log('not found arrayindex: ' + param.name + '/' + p.param);
                    }
                  } else {
                    // Regular non-array parameter
                    const value = dev.Monitorings.find(ex => ex.Name.toUpperCase() === p.param.toUpperCase());
                    if (value !== null && value !== undefined) {
                      if (!isNaN(p.value)) {
                        p.value = Math.round(p.value * 100) / 100;
                      }
                      value.SetValue(p.value);
                    } else {
                      console.log('not found: ' + param.name + '/' + p.param);
                    }
                  }
                }
              } else {
                console.log('Device not found: ' + param.name);
              }
            }
          }

          if (msg.parameter !== null && msg.parameter !== undefined) {
            const dev = this.customerModule.Module.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');

            if (dev !== null && dev !== undefined) {
              for (const p of msg.parameter) {
                const value = dev.Monitorings.find(ex => ex.Name.toUpperCase() === p.param.toUpperCase());
                if (value !== null && value !== undefined) {


                  if (!isNaN(p.value)) {
                    p.value = Math.round(p.value * 100) / 100;
                  }
                  value.SetValue(p.value);
                } else {
                }
              }
            }

          }
        }
      }
    }
  }

  public Save() {

    // SAVE CHANGES
    this.moduleHandling.SaveModuleSettings(this.customerModule);
    this.moduleHandling.saveTempProject();





  }

  public ClickOnLine() {

  }

  public ClickOnObjectPlate() {
    // this.settingWidth = '30px';
    // this.lineWidth = '30px';
    // this.imageWidth = 'calc(100% - 90px)';
  }

  public SignalTest() {
    // new: send signal
    if (this.moduleHandling.CurrentViewMode === ViewCode.live || this.moduleHandling.CurrentViewMode === ViewCode.inventory) {
      if (this.customerModule) {
        if (environment.demo === false)
        this.messaging.ModuleSignalTest(uuidV4(), this.customerModule.SerialNumber);
      }
    }
  }

  public LoadCurrentConfig() {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live || this.moduleHandling.CurrentViewMode === ViewCode.inventory) {
      if (this.customerModule && this.customerModule.SerialNumberSetted) {
        const msg = {
          type: MessageTypes.REQUEST,
          context: ContextTypes.MODULE,
          ctxId: this.customerModule.SerialNumber,
          msgId: null,
          entity: Entities.RECIPE
        };

        this.messageIdRecipe = uuidV4();
        this.messaging.RequestModuleConfiguration(this.messageIdRecipe, this.customerModule.SerialNumber);

      }
    }
  }

  public SendToModule(device: ModuleComponent) {

    if (this.moduleHandling.CurrentViewMode === ViewCode.live || this.moduleHandling.CurrentViewMode === ViewCode.inventory) {

      if (this.customerModule && this.customerModule.SerialNumberSetted) {

        let values: ServiceValues[];

        values = [];

        for (const vl of device.Configs.filter(ex => ex.ConstallationDependency === false)) {
          if (vl.CurrentValue !== null) {
            values.push(new ServiceValues(vl.Name, vl.CurrentValue));

          }
        }


        const servicemodul = new ServiceModule(this.customerModule.SerialNumber, ' ', device.PlcKey, values);
        const serviceMessage = new ServiceMessage();
        serviceMessage.ServiceModul = servicemodul;

        if (this.originModule.Module.Connected) {

          if (this.originModule.Module.CurrentMode !== ModeSwitch.SERVICE) {

            const dialogRef2 = this.dialog.open(MessageBoxComponent,
              {width: 200 + 'px', panelClass: 'panelclass', data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOTINSERVICE')});
            dialogRef2.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
            return;
          }

          // Send


          const vv = [];

          for (const v of device.Configs.filter(ex => ex.ConstallationDependency === false)) {

            if (v.CurrentValue === undefined || v.CurrentValue === null) {
              if (v.MinValue !== null && v.MinValue !== undefined) {
                v.CurrentValue = v.MinValue;
              } else {
                v.CurrentValue = '0';
              }
            }

            vv.push({
              param: v.Name,
              value: v.CurrentValue.toString()
            });
          }
          const dev = {
            name: device.PlcKey,
            params: vv
          };

          const devs = [];
          devs.push(dev);
          this.messaging.sendServiceConfig(uuidV4(), this.originModule.SerialNumber, devs);
          // this.messaging.sendMessage(msg);
          const dialogRef = this.dialog.open(MessageBoxComponent,
            {width: 200 + 'px', panelClass: 'panelclass', data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.SERVICECONFIGLOADED')});
          dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});

          this.lastChanged = null;

        } else {
          const dialogRef = this.dialog.open(MessageBoxComponent,
            {width: 200 + 'px', panelClass: 'panelclass', data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOTCONNECTED')});
          dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
          dialogRef.afterClosed().subscribe(result => {

          });
        }
      }
    }
  }

  public ConfigrationChanged(device: ModuleComponent) {
    this.customerModule.Module.ConfigurationChanged = true;
  }

  public SaveConfig() {
    if (this.ValidateConfiguration()) {
      this.originModule.Module.HaveConfiguration = true;

      for (const d of this.customerModule.Module.Components) {
        const orgDev = this.originModule.Module.Components.find(ex => ex.PlcKey === d.PlcKey);
        if (orgDev) {
          for (const vl of d.Configs.filter(ex => !ex.Mode || ex.Mode !== 'Service')) {
            const orgDevVal = orgDev.Configs.find(ex => ex.Name === vl.Name);
            if (orgDevVal !== null && orgDevVal !== undefined) {
              if (vl.MaxValue !== orgDevVal.MaxValue) {
                orgDevVal.MaxValue = vl.MaxValue;
              }
              if (vl.MinValue !== orgDevVal.MinValue) {
                orgDevVal.MinValue = vl.MinValue;
              }

              if (vl.DefaultValue !== orgDevVal.DefaultValue) {
                orgDevVal._defaultValue = vl.DefaultValue;
              }

              if (vl.RecipeDirection !== orgDevVal.RecipeDirection) {
                orgDevVal.RecipeDirection = vl.RecipeDirection;
              }


              if (vl.CurrentValue !== orgDevVal.CurrentValue) {
                orgDevVal.SetValue(vl.CurrentValue);
              }
            }
          }
        }
      }
      this.moduleHandling.saveTempProject();
      this.updateServiceValuesFromConfig();

      const dialogRef = this.dialog.open(MessageBoxComponent,
        {width: 200 + 'px', panelClass: 'panelclass', data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.CONFIGSAVED'), timeout: 500}});
      dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
      this.customerModule.Module.ConfigurationChanged = false;

    } else {
      const dialogRef = this.dialog.open(MessageBoxComponent,
        {
          width: 200 + 'px',
          panelClass: 'panelclass',
          data: this.translate.GetTranslation('MESSAGEBOX.CONTENT.CONFIGINCORRECT')
        });
      dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});

    }
  }

  public ValidateConfiguration(): boolean {
    if (this.customerModule) {
      for (const dev of this.customerModule.Module.Components) {
        for (const vl of dev.Configs.filter(ex => ex.ConstallationDependency !== true && (!ex.Mode || ex.Mode !== 'Service') && ex.Excluded !== true)) {

          let skip = false;
          if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
            if (vl.DesignRelevant === false) {
              skip = true;
            }
          }
          if (!skip) {
            if (vl.CurrentValue === null || vl.CurrentValue === undefined) {
              return false;
            } else {
              if (vl.DirectionDependency) {
                if (vl.Direction < 0) {
                  if (vl.CurrentValue > vl.MinValue || vl.CurrentValue < vl.MaxValue) {
                    return false;
                  }
                } else {
                  if (vl.CurrentValue < vl.MinValue || vl.CurrentValue > vl.MaxValue) {
                    return false;
                  }
                }
              } else {
                if (vl.States.length > 0) {
                  if (!vl.States.find(ex => ex.State === vl.CurrentValue)) {
                    return false;
                  }
                }
                if (vl.CurrentValue < vl.MinValue || vl.CurrentValue > vl.MaxValue) {
                  return false;
                }
              }
            }
          }
        }
      }
    }
    return true;
  }

  public GetDevicesWithConfig(): ModuleComponent[] {
    if (this.customerModule) {
      return this.customerModule.Module.Components.filter(ex => ex.Configs.filter(et => et.ConstallationDependency === false).length > 0);
    }
  }

  public GetDevicesWithMaintance(): ModuleComponent[] {
    if (this.customerModule) {
      return this.customerModule.Module.Components.filter(ex =>
        ex.Maintenance.filter(et => et.ConstallationDependency === false).length > 0);
    }
  }

  public GetMonitoringDevices(): ModuleComponent[] {
    if (this.customerModule) {
      return this.customerModule.Module.Components.filter(ex => ex.Monitorings.length > 0 && ex.Virtual === false);
    } else {
      return [];
    }
  }

  public clickComment() {

    if (this.customerModule) {
      if (this.CommentOnEdit) {

      }
      this.originModule.Comment = this.customerModule.Comment;
      this.CommentOnEdit = !this.CommentOnEdit;
    }
  }

  public RequestDownload(device: ModuleComponent) {
    // OPEN DIALOG

    // GET DATA
    if (device) {

      // GET PARAM
      const param = device.Configs.find(ex => ex.DownloadPossible === true);

      if (param) {


        const data = {
          serial: this.customerModule.SerialNumber,
          moduleType: this.customerModule.Module.Type,
          component: device.PlcKey,
          variableName: param.GetName(),
          options: param.SelectionList,
          displayPath: param.DisplayPath,
          valuePath: param.ValuePath
        };

        /*
         [
            {Number: 1,
            Name: 'test1'},
            {Number: 2,
              Name: 'test2'},
            {Number: 3,
              Name: 'test3'}
          ], //
         */

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'panelclass';
        dialogConfig.data = data;

        this.dialog.open(DownloadFromModuleDialogComponent,
          dialogConfig);

      }

    }


  }

  public RequestUpload(device: ModuleComponent) {
    // OPEN DIALOG
  }

  public SelectComponent($event) {


    this.selectedComponent = $event as string;
    const component = this._modulePlan.modul.Components.find(ex => ex.PlcKey === this.selectedComponent);
    if (component) {

      if (this.customerModule.Module.CurrentMode === ModeSwitch.SERVICE || this.currentLocation === 'localhost' || this.currentLocation === 'theme') {
        this.customerModule.Module.LastServiceConfiguration = null;
        this.customerModule.Module.ConfigurationChanged = true;
      }

      this.moduleHandling.ModuleDeviceSelect(component);
    }

    if (this.modulePathData) {

      let key = '"moduleHover"';
      let toend = 13;

      if (this.ShowService) {
        key = '"inventory"';
        toend = 11;
      }

      this.modulePathData = this.modulePathData.replace('class="selected"', 'class=' + key);


      if (this.modulePathData.indexOf('id="' + this.selectedComponent + '"') >= 0) {
        const indexofClass = this.modulePathData.indexOf('class=', this.modulePathData.indexOf('id="' + this.selectedComponent + '"'));
        let indexofClassEnd = indexofClass + 6 + toend;

          // this.modulePathData.indexOf(key,
          // this.modulePathData.indexOf('id="' + this.selectedComponent + '"'));
        // indexofClassEnd += toend;

        const part1 = this.modulePathData.substr(0, indexofClass);
        const part2 = this.modulePathData.substr(indexofClassEnd, this.modulePathData.length - indexofClassEnd);

        this.modulePathData = part1 + 'class="selected"' + part2;

        this.moduleComponents.nativeElement.innerHTML =
          this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.modulePathData));

      }
    }
  }

  public ShowConfigurationChanged(): boolean {

    if (this.customerModule) {
      if (this.customerModule.Module.CurrentMode !== ModeSwitch.SERVICE) {
        return this.customerModule.Module.ConfigurationChanged;
      }

    }

    return false;
  }

  public LoadConfigPossible(): boolean {
    if (environment.demo === false) {
      if (this.customerModule) {
        return this.customerModule.SerialNumberSetted &&
          this.moduleHandling.CurrentViewMode === ViewCode.live &&
          this.customerModule.Module.CurrentMode !== ModeSwitch.SERVICE;
      }
    }

    return false;
  }

  public OnSelectedComponentChanged(value: string) {
    this.SelectComponent(value);
  }

  public OnServiceSelectionChanged(component: ModuleComponent) {
    this.ServiceSelectionChanged = true;

    if (this.customerModule.Module.NetworkInfo) {
      if (this.customerModule.Module.NetworkInfo.nodeversion) {

        let version = this.customerModule.Module.NetworkInfo.nodeversion;
        if (version.indexOf('.dev') >= 0) {
          version = version.substring(0, version.indexOf('.dev'));
        }
          if (compareVersions(version, '1.2.0-rc.2') < 1) {
            for (const comp of this.customerModule.Module.Components) {
              if (comp.PlcKey !== component.PlcKey) {
                comp.ServiceSelected = false;
              }
            }
          }
      }
    }
  }


  public ServiceUploadPossible(): boolean {
    if (this.currentLocation === 'localhost' || this.currentLocation === 'theme') {
      if (this.customerModule.Module.Components.filter(ex => ex.ServiceSelected === true).length > 0) {
        if (this.ServiceSelectionChanged) {
          return true;
        }



      }
    }

    if (this.customerModule) {
      if (this.customerModule.Module.CurrentMode === ModeSwitch.SERVICE) {
        if (this.customerModule.Module.Components.filter(ex => ex.ServiceSelected === true).length > 0) {
          if (this.ServiceSelectionChanged) {
            // IF CONNECTED
            if (this.customerModule.Module.NetworkInfo) {
              return true;
            } else {
              return true;
            }
          }
        }

      }
    }

    return false;
  }

  public ShowStartButton(): boolean {

    if (this.currentLocation === 'localhost' || this.currentLocation === 'theme') {
      if (this.customerModule.Module.Components.filter(ex => ex.ServiceSelected === true).length > 0) {
        if (this.customerModule.Module.LastServiceConfiguration) {
          if (!this.ServiceSelectionChanged) {
            return true;
          }
        }

      }
    }

    // return true;
    if (this.customerModule) {
      if (this.customerModule.Module.CurrentMode === ModeSwitch.SERVICE) {
        if (this.customerModule.Module.LastServiceConfiguration && !this.ServiceSelectionChanged) {
          if (this.customerModule.Module.NetworkInfo) {
            return true;
          } else {
            return true;
          }
        }

      }
    }
    return false;
  }




  public UploadService() {
    if (this.customerModule.Module.Components.filter(ex => ex.ServiceSelected === true).length > 0) {
      // this.customerModule.Module.NetworkInfo) {
      this.ServiceSelectionChanged = false;

      if (this.customerModule.Module.NetworkInfo) {
        if (this.customerModule.Module.NetworkInfo.nodeversion) {
          let version = this.customerModule.Module.NetworkInfo.nodeversion;
          if (version.indexOf('.dev') >= 0) {
            version = version.substring(0, version.indexOf('.dev'));
          }
          if (compareVersions(version, '1.2.0-rc.2') < 1) {
            const selected = this.customerModule.Module.Components.find(ex => ex.ServiceSelected === true);
            this.customerModule.Module.LastServiceConfiguration = null;
            if (selected != null) {
              const params = [];
              for (const param of selected.Maintenance.filter(ex => ex.Virtual === false)) {
                if (param.Name.toUpperCase() !== 'SERVICEACTIVE') {
                  let val = param.getValue();
                  if (val !== null && val !== undefined) {
                    if (param.ShowPositiveOnly === true) {
                      val = val * param.RecipeDirection;
                    }
                    params.push({
                      param: param.Name,
                      value: val
                    });
                  }
                }
              }
              this.customerModule.Module.LastServiceConfiguration = {
                name: selected.PlcKey,
                params: params
              };
              this.originModule.Module.LastServiceConfiguration = this.customerModule.Module.LastServiceConfiguration;
              if (this.clusterOnboarding.ClusterInitialized) {
                this.msgId = uuidV4();
                this.messaging.sendServiceConfigOld(this.msgId,
                  this.customerModule.SerialNumber,
                  this.customerModule.Module.LastServiceConfiguration);
              }
            }
            return;
          }
        }
      }

      this.customerModule.Module.LastServiceConfiguration = [];

      for (const component of this.customerModule.Module.Components.filter(ex => ex.ServiceSelected === true)) {
        const params = [];
        for (const param of component.Maintenance.filter(ex => ex.Virtual === false)) {
          if (param.Name.toUpperCase() !== 'SERVICEACTIVE') {
            let val = param.getValue();
            if (val !== null && val !== undefined) {
              if (param.ShowPositiveOnly === true) {
                val = val * param.RecipeDirection;
              }
              params.push({
                param: param.Name,
                value: val
              });
            }
          }
        }
        this.customerModule.Module.LastServiceConfiguration.push({
          name: component.PlcKey,
          params: params
        });
      }
      this.originModule.Module.LastServiceConfiguration = this.customerModule.Module.LastServiceConfiguration;
      if (this.clusterOnboarding.ClusterInitialized) {
        this.msgId = uuidV4();
        this.messaging.sendServiceConfig(this.msgId,
          this.customerModule.SerialNumber,
          this.customerModule.Module.LastServiceConfiguration);
      }
    }
  }

  public ShowStartDialog() {
    // SHOW DIALOG
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'panelclass';

    dialogConfig.data = this.customerModule;
    this.dialog.open(RunServiceConfigurationComponent,
      dialogConfig);
  }

  public setRandomNotifications() {
    this.originModule.ActiveErrorNotifications = [];
    this.originModule.ActiveWarningNotifications = [];

    // for (const comp of this.originModule.Module.Components) {
    //   if (comp.Errors.length > 6) {
    //     for (let i = 0; i < 6; i++) {
    //       this.originModule.ActiveErrorNotifications.push(comp.Errors[i]);
    //     }
    //   }
    //
    //   if (comp.Warnings.length > 6) {
    //     for (let i = 0; i < 6; i++) {
    //       this.originModule.ActiveWarningNotifications.push(comp.Warnings[i]);
    //     }
    //   }
    // }

  }

  public GetNotificationCode(notification: NotificationEntry): string {
    // const code = this.translate.GetTranslation(notification.TranslateIDNumber);
    // if (code && code !== notification.TranslateIDNumber) {
    //   return code;
    // } else {
    //   return notification.Index.toString();
    // }
    return notification.TextNumber;
  }

  public GetNotificationComponentNumber(notification: NotificationEntry): string {

    if (notification.Component) {
      const nmbr = notification.Component.substr(notification.Component.length - 2, 2);
      const nbr = Number(nmbr);
      if (nbr) {
        return nbr.toString();
      }
    }
    return '';
  }

  public GetNotificationText(notification: NotificationEntry): string {
    const text = this.translate.GetTranslation(notification.TranslateIDText);
    if (text && text !== notification.TranslateIDText) {
      return text;
    } else {
      return notification.Index.toString();
    }
  }

  // public GetNotificationBmK(notification: NotificationEntry): string {
  //   const text = this.translate.GetTranslation(notification.Bmk);
  //   if (text && text !== notification.Bmk) {
  //     return text;
  //   } else {
  //     return '';
  //   }
  // }

  public ShowMonitoringTab(): boolean {
    if (this.currentLocation === 'theme' || this.currentLocation === 'localhost') {
      return true;
    }

    return this.moduleHandling.CurrentViewMode === ViewCode.live && this._modulePlan.modul.CurrentMode !== ModeSwitch.SERVICE;
  }

  public ShowParameterTab(): boolean {
    if (this.currentLocation === 'theme' || this.currentLocation === 'localhost') {
      return true;
    }

    return this._modulePlan.modul.CurrentMode !== ModeSwitch.SERVICE;
  }

  public ShowServiceTab(): boolean {
    if (this.currentLocation === 'theme' || this.currentLocation === 'localhost') {
      return true;
    }

    return this._modulePlan.modul.CurrentMode === ModeSwitch.SERVICE;
  }

  public onTabChanged($event) {

    if ($event.tab.textLabel === 'Parameter') {
      this.SelectParameterTab();
    } else if ($event.tab.textLabel === 'Service') {
      this.SelectServiceTab();
    } else if ($event.tab.textLabel === 'Monitoring') {
      this.SelectMonitoringTab();
    } else {
      this.ShowMonitoring = false;
      this.ShowParameter = false;
      this.ShowService = false;

      this.UpdatePaths();
    }
  }


  public SelectParameterTab() {
    this.ShowMonitoring = false;
    this.ShowParameter = true;
    this.ShowService = false;
    this.UpdatePaths();
  }

  public SelectMonitoringTab() {
    this.ShowMonitoring = true;
    this.ShowParameter = false;
    this.ShowService = false;
    this.UpdatePaths();
  }

  public SelectServiceTab() {
    this.ShowMonitoring = false;
    this.ShowParameter = false;
    this.ShowService = true;
    this.UpdatePaths();
  }

  private UpdatePaths() {
    if (this.ShowParameter) {
      if (this.modulePathData) {
        // this.modulePathData = this.modulePathData.replace('class="selected"', 'class="moduleHover"');

        let data = this.completeModulePathData;

        for (const c of this.customerModule.Module.Components) {
          if (c.Configs.length <= 0 || c.Configs.filter(ex => ex.ConstallationDependency === false).length <= 0) {
            // REMOVE IT
            const indexofClass = data.indexOf('id="' + c.PlcKey + '"');
            if (indexofClass >= 0) {

              let keyWord = 'path';
              let startIndex = indexofClass;

              for (let i = indexofClass; i >= 0; i--) {
                if (data.substr(i, 1) === '<') {
                  // FOUND IT
                  startIndex = i;
                  if (data.substr(i, 4) === '<pol') {
                    keyWord = 'polygon';
                  }
                  break;
                }
              }



              const endIndex = data.indexOf('/>', indexofClass);

              if (endIndex > 0) {
                const firstSt = data.substr(0, startIndex);
                const lastSt = data.substr(endIndex + 3);




                data = firstSt + lastSt;
              }
            }
          }
        }
        this.modulePathData = data;

        this.moduleComponents.nativeElement.innerHTML =
          this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.modulePathData));

      }
    } else if (this.ShowMonitoring) {
      if (this.modulePathData) {
        // this.modulePathData = this.modulePathData.replace('class="selected"', 'class="moduleHover"');

        let data = this.completeModulePathData;
        for (const c of this.customerModule.Module.Components) {
          if (c.Monitorings.length <= 0) {
            // REMOVE IT
            const indexofClass = data.indexOf('id="' + c.PlcKey + '"');
            if (indexofClass >= 0) {

              let keyWord = 'path';
              let startIndex = indexofClass;

              for (let i = indexofClass; i >= 0; i--) {
                if (data.substr(i, 1) === '<') {
                  // FOUND IT
                  startIndex = i;
                  if (data.substr(i, 4) === '<pol') {
                    keyWord = 'polygon';
                  }
                  break;
                }
              }

              const endIndex = data.indexOf('/>', indexofClass);

              if (endIndex > 0) {
                const firstSt = data.substr(0, startIndex - 1);
                const lastSt = data.substr(endIndex + 3);
                data = firstSt + lastSt;
              }
            }
          }
        }
        this.modulePathData = data;
        this.moduleComponents.nativeElement.innerHTML =
          this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.modulePathData));

      }
    }  else if (this.ShowService) {
      if (this.modulePathData) {
        // this.modulePathData = this.modulePathData.replace('class="selected"', 'class="moduleHover"');

        let data = this.completeModulePathData;

        data = data.replace('class="selected"', 'class="inventory"');
        data = data.replace('class="moduleHover"', 'class="inventory"');


        for (const c of this.customerModule.Module.Components) {

          data = data.replace('class="moduleHover"', 'class="inventory"');

          if (c.Maintenance.length <= 0) {
            // REMOVE IT
            const indexofClass = data.indexOf('id="' + c.PlcKey + '"');
            if (indexofClass >= 0) {

              let keyWord = 'path';
              let startIndex = indexofClass;

              for (let i = indexofClass; i >= 0; i--) {
                if (data.substr(i, 1) === '<') {
                  // FOUND IT
                  startIndex = i;
                  if (data.substr(i, 4) === '<pol') {
                    keyWord = 'polygon';
                  }
                  break;
                }
              }

              const endIndex = data.indexOf('/>', indexofClass);

              if (endIndex > 0) {
                const firstSt = data.substr(0, startIndex - 1);
                const lastSt = data.substr(endIndex + 3);
                data = firstSt + lastSt;
              }
            }
          }
        }
        this.modulePathData = data;
        this.moduleComponents.nativeElement.innerHTML =
          this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.modulePathData));

      }
    } else {
      this.modulePathData = this.completeModulePathData;
      this.moduleComponents.nativeElement.innerHTML =
        this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.modulePathData));
    }
  }


}
